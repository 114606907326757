@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap');
@font-face {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
  font-display: swap;
}

:root{
  --primary-color: #ffffff;
  --secondary-color: #f0f0f0;
  --tertiary-color: rgba(227,227,232,255);
  --text-color: rgb(44, 44, 44);
  --primary-accent-color: #007aff;
  --secondary-accent-color: #35c759;
}
html,
body{
  margin-top: 6em;
  margin-bottom: 2em;
}

body {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  background-color: var(--secondary-color);
  color: var(--text-color);
}


h1,
h2,
p,
li,
ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
.title{
  text-align: center;
}
#root{
  max-height: 70%;
}

.width-container{
  width: 80%;
  margin: 0 auto;
}
a{
  text-decoration: none;
  color: var(--text-color);
}
.content{
  margin-bottom: 5em;
}


/* Footer */
.footer{
  background-color: var(--primary-color);
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 1.5em;
  
}

.bottom-bar{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  
}

.footer-link{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  padding-top: .3em;
  margin-top: 0;
  border-radius: 18px;
}